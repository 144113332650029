/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "box-sizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#box-sizing",
    "aria-label": "box sizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Box Sizing"), "\n", React.createElement(_components.p, null, "Utilities for controlling how the browser should calculate an element's total size."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "boxSizing={keyword}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "box-sizing: {keyword};"))))), "\n", React.createElement(_components.h2, {
    id: "include-borders-and-padding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#include-borders-and-padding",
    "aria-label": "include borders and padding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Include borders and padding"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "boxSizing=\"border-box\""), " to set an element's ", React.createElement(_components.code, null, "box-sizing"), " to ", React.createElement(_components.code, null, "border-box"), ", telling the browser to include the element's borders and padding when you give it a height or width."), "\n", React.createElement(_components.p, null, "This means a 100px × 100px element with a 2px border and 4px of padding on all sides will be rendered as 100px × 100px, with an internal content area of 88px × 88px."), "\n", React.createElement(_components.p, null, "xstyled makes this the default for all elements in our ", React.createElement(_components.a, {
    href: "/docs/preflight/"
  }, "preflight base styles"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" alignItems=\"center\" justifyContent=\"center\">\n      <x.div\n        boxSizing=\"border-box\"\n        h={32}\n        w={32}\n        p={4}\n        border={4}\n        borderColor=\"light-blue-400\"\n        bg=\"light-blue-200\"\n        borderRadius=\"md\"\n      >\n        <x.div h={1} w={1} bg=\"light-blue-400\" />\n      </x.div>\n    </x.div>\n  </template>\n  <x.div boxSizing=\"border-box\" h={32} w={32} p={4} border={4}>\n    {/* ... */}\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "exclude-borders-and-padding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#exclude-borders-and-padding",
    "aria-label": "exclude borders and padding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Exclude borders and padding"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "boxSizing=\"content-box\""), " to set an element's ", React.createElement(_components.code, null, "box-sizing"), " to ", React.createElement(_components.code, null, "content-box"), ", telling the browser to add borders and padding on top of the element's specified width or height."), "\n", React.createElement(_components.p, null, "This means a 100px × 100px element with a 2px border and 4px of padding on all sides will actually be rendered as 112px × 112px, with an internal content area of 100px × 100px."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=rose"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" alignItems=\"center\" justifyContent=\"center\">\n      <x.div\n        boxSizing=\"content-box\"\n        h={32}\n        w={32}\n        p={4}\n        border={4}\n        borderColor=\"rose-400\"\n        bg=\"rose-200\"\n        borderRadius=\"md\"\n      >\n        <x.div h={1} w={1} bg=\"rose-400\" />\n      </x.div>\n    </x.div>\n  </template>\n  <x.div boxSizing=\"content-box\" h={32} w={32} p={4} border={4}>\n    {/* ... */}\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the box-sizing at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "boxSizing={{ md: \"content-box\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "boxSizing=\"content-box\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div boxSizing={{ md: 'content-box' }} />\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
